.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

 

.select-container { 
  margin-bottom: 20px;
}

.select-options {
  position: relative;
  width: auto;
}
.with-border {
    border: 1px solid #ccc; /* Add border styling */
    padding: 5px; /* Add some padding */
}

.entered-value {
    border: 1px solid #ccc; /* Add border styling to entered values */
    border-radius: 5px;
    padding: 5px; /* Add some padding */
    display: inline-block; /* Display entered values inline */
    margin-right: 5px; /* Add margin for spacing */
}

.select-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.hidden-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.selected-options {
  width: auto;
}

.selected-option {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.remove-option {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.remove-option:hover {
  color: darkred;
}

