 

.select-container { 
  margin-bottom: 20px;
}

.select-options {
  position: relative;
  width: auto;
}
.with-border {
    border: 1px solid #ccc; /* Add border styling */
    padding: 5px; /* Add some padding */
}

.entered-value {
    border: 1px solid #ccc; /* Add border styling to entered values */
    border-radius: 5px;
    padding: 5px; /* Add some padding */
    display: inline-block; /* Display entered values inline */
    margin-right: 5px; /* Add margin for spacing */
}

.select-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.hidden-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.selected-options {
  width: auto;
}

.selected-option {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.remove-option {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.remove-option:hover {
  color: darkred;
}
